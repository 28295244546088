import { compress, decompress } from 'lz-string'

/**
 *
 */
const set = (key: string, data: any, expirationInSeconds = 0) => {
	sessionStorage.setItem(
		`cache-${key}`,
		JSON.stringify({
			dt: new Date().getTime() + expirationInSeconds * 1000,
			data: compress(JSON.stringify(data))
		})
	)
	cleanOutdatedCache()
}

/**
 *
 */
const get = (key: string) => {
	const item = sessionStorage.getItem(`cache-${key}`)
	if (!item) return undefined

	try {
		const json = JSON.parse(item) as TCacheItem

		if (json.dt - new Date().getTime() < 0) return undefined

		return JSON.parse(decompress(json.data))
	} catch {
		return undefined
	}
}

/**
 *
 */
const removeItem = (key: string) => {
	sessionStorage.removeItem(`cache-${key}`)
}

/**
 *
 */
const cleanOutdatedCache = async () => {
	const sessionStorageKeys = Object.keys(sessionStorage)

	for (const key of sessionStorageKeys) {
		if (key.startsWith('cache-')) {
			try {
				const json = JSON.parse(sessionStorage.getItem(key)) as TCacheItem
				if (new Date().getTime() > json.dt) sessionStorage.removeItem(key)
			} catch {
				//
			}
		}
	}
}

/**
 *
 */
const clean = () => {
	const sessionStorageKeys = Object.keys(sessionStorage)

	for (const key of sessionStorageKeys) {
		if (key.startsWith('cache-')) {
			sessionStorage.removeItem(key)
		}
	}
}

/**
 *
 */
const cache = {
	set,
	get,
	removeItem,
	clean,
	cleanOutdatedCache
}

export default cache

type TCacheItem = {
	dt: number
	data: string
}
