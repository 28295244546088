import { getPictureURL as aws_getPictureURL } from './aws.image'
const CLOUDINARY_BASE_URL = `https://res.cloudinary.com/coq-trotteur/image/upload`

export type TGetPictureURLParams = {
	w?: number
	h?: number
	c?: 'fill' | 'limit'
	q?: 'auto' | number
	f?: 'auto' | 'jpg'
}

const defaultParams: TGetPictureURLParams = {
	f: 'auto',
	q: 'auto'
}

const generateCloudinaryURLParamsString = (params: TGetPictureURLParams) => {
	const list = []

	Object.keys(params).forEach((key) => {
		const value = params[key]

		if (['w', 'h'].includes(key)) {
			list.push(`${key}_${Math.ceil(value * window.devicePixelRatio)}`)
		} else {
			if (value !== null) list.push(`${key}_${value}`)
		}
	})

	return list.join(',')
}

const generateCloudinaryURL = (public_id, params: TGetPictureURLParams) => {
	const paramsString = generateCloudinaryURLParamsString({
		...defaultParams,
		...params
	})
	return `${CLOUDINARY_BASE_URL}/${paramsString}/${public_id}`
}

export const getPictureURL = (picture: any, params?: TGetPictureURLParams) => {
	if (picture?.url) {
		return aws_getPictureURL(picture, params as any)
	}

	if (picture?.public_id) {
		return generateCloudinaryURL(picture.public_id, params || {})
	}

	return null
}

export default {
	getPictureURL
}
