import React from 'react'
import { getColorFromName, TIconColor } from './Icons.common'

const transformDirectionMatch = {
	left: 0,
	right: 180,
	up: 90,
	down: -90
}

const ArrowIcon = ({
	height = 23,
	width = 23,
	direction,
	color = 'dark'
}: {
	height?: number
	width?: number
	direction: 'left' | 'right' | 'up' | 'down'
	color?: TIconColor
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 23 23"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="icon"
		style={{
			transform: `rotate(${transformDirectionMatch[direction]}deg)`,
			WebkitTransform: `rotate(${transformDirectionMatch[direction]}deg)`
		}}
	>
		<path
			d="M22.3529 11.3137C22.3529 11.6795 22.0565 11.9759 21.6913 11.9754L2.54636 11.9754L6.12596 15.555C6.3861 15.8151 6.38606 16.2373 6.12596 16.4974C5.86582 16.7576 5.44359 16.7575 5.18349 16.4974L0.471005 11.7849C0.346125 11.6601 0.27401 11.4909 0.274049 11.3137C0.274089 11.1365 0.346125 10.9674 0.471004 10.8425L5.18349 6.12999C5.44363 5.86985 5.86586 5.86989 6.12596 6.12999C6.38606 6.39009 6.38606 6.81236 6.12596 7.07246L2.54636 10.6521L21.6913 10.6521C22.057 10.6521 22.3534 10.9485 22.3529 11.3137Z"
			fill={getColorFromName(color)}
		/>
	</svg>
)
export default ArrowIcon
