import { gql } from 'apollo-boost'
import { TWebsite } from '~types/Website.types'
import cache from '~utils/cache/cache'
import { doGraphqlQuery } from './common.queries'

/**
 *
 */
export const fetchWebsiteProducts = async (loadActivityMiniature = false) => {
	const cached = cache.get(`website-products`)
	if (cached !== undefined) return cached

	const query = gql`
		query FetchWebsiteProducts(
			$clientFilter: ClientFilterInput
			$websiteId: ID!
		) {
			fetchWebsiteProducts(clientFilter: $clientFilter, websiteId: $websiteId) {
				${searchResultMarketItemFragment}
                ${loadActivityMiniature ? activitiesMiniatureFragment : ''}
			}
		}
	`

	const variables = {
		clientFilter: {},
		websiteId: process.env.GATSBY_WEBSITE_ID
	}

	const { fetchWebsiteProducts } = await doGraphqlQuery({
		query,
		variables,
		apiVersion: 2
	})

	cache.set(`website-products`, fetchWebsiteProducts, 60)
	return fetchWebsiteProducts
}

/**
 *
 */
export const fetchWebsiteServices = async (loadActivityMiniature = false) => {
	const cached = cache.get(`website-services`)
	if (cached !== undefined) return cached

	const query = gql`
		query FetchWebsiteServices(
			$clientFilter: ClientFilterInput
			$websiteId: ID!
		) {
			fetchWebsiteServices(clientFilter: $clientFilter, websiteId: $websiteId) {
				${searchResultMarketItemFragment}
                ${loadActivityMiniature ? activitiesMiniatureFragment : ''}
			}
		}
	`
	const variables = {
		clientFilter: {},
		websiteId: process.env.GATSBY_WEBSITE_ID
	}

	const { fetchWebsiteServices } = await doGraphqlQuery({
		query,
		variables,
		apiVersion: 2
	})

	cache.set(`website-services`, fetchWebsiteServices, 60)
	return fetchWebsiteServices
}

//
export const getBoutiqueItems = async (
	activitiesId: string[],
	light = false
) => {
	// console.log('getBoutiqueItems', activitiesId)
	const result = activitiesId
		? await Promise.all(
				activitiesId.map((id) => getActivityBoutiqueItems(id, light))
		  )
		: []

	const joined = result.reduce((acc: any[], cur: any[]) => [...acc, ...cur], [])

	return Array.from(new Set(joined.map((a) => a.id))).map((id) => {
		return joined.find((a) => a.id === id)
	})
}

export const getActivityBoutiqueItems = async (activityId, light = false) => {
	const query = gql`
		query FetchActivityMarketItems($activityId: ID!, $clientFilter: ClientFilterInput) {
			fetchActivityMarketItems(activityId: $activityId, clientFilter: $clientFilter) {
				${light ? lightMarketItemFragment : marketItemFragment}
			}
		}
	`

	const variables = {
		activityId,
		clientFilter: {
			orderBy: {
				property: 'createdAt',
				direction: 'Desc'
			}
		}
	}

	const { fetchActivityMarketItems } = await doGraphqlQuery({
		query,
		variables,
		apiVersion: 2
	})

	return fetchActivityMarketItems.filter((marketItem) => marketItem.enabled)
}

export const getBoutiqueCategories = async (): Promise<
	Pick<TWebsite, 'boutique'>
> => {
	//console.log('fetchWebsite =>> getBoutiqueCategories')

	const websiteId = process.env.GATSBY_WEBSITE_ID
	const query = gql`
		query FetchBoutiqueCategories($websiteId: ID!) {
			fetchWebsite(websiteId: $websiteId) {
				boutique {
					categories {
						id
						label
						marketItems
						children {
							id
							label
							marketItems
							children {
								id
								label
								marketItems
							}
						}
					}
				}
			}
		}
	`
	const variables = {
		websiteId
	}

	const { fetchWebsite } = await doGraphqlQuery({
		query,
		variables,
		apiVersion: 2
	})

	return fetchWebsite.boutique.categories
}

export const getBoutiqueItemTitleAndImage = async (marketItemId: string) => {
	const query = gql`
		query FetchMarketItem($marketItemId: ID!) {
			fetchMarketItem(marketItemId: $marketItemId) {
				title
				type
				gallery {
					url
					public_id
				}
			}
		}
	`
	const variables = {
		marketItemId
	}

	const { fetchMarketItem } = await doGraphqlQuery({
		query,
		variables,
		apiVersion: 2
	})

	return fetchMarketItem
}

export const getBoutiqueItem = async (marketItemId: string) => {
	const cached = cache.get(`website-item-${marketItemId}`)
	if (cached !== undefined) return cached

	const query = gql`
	query FetchMarketItem($marketItemId: ID!) {
		fetchMarketItem(marketItemId: $marketItemId) {
			${marketItemFragment}
		}
	}
`
	const variables = {
		marketItemId
	}

	const { fetchMarketItem } = await doGraphqlQuery({
		query,
		variables,
		apiVersion: 2
	})

	cache.set(`website-item-${marketItemId}`, fetchMarketItem, 30)

	return fetchMarketItem
}

export const serviceFragment = `
about {
    note
    inclusions {
        has
        hasnt
    }
}

service_availability {
    type
    unique_schedule
    global_closed_period
    schedules {
        id
        unique_week_schedule
        purpose
        closed_periods {
            id
            start_date
            end_date
        }
        week_schedules {
            id
            periods {
                start_date
                end_date
                id
            }
            days
        }
    }
}

private_group {
    enabled
    min_visitor_count
    max_visitor_count
}

service_location {
    enabled
    address {
        full_address
    }
    note
}
service_duration {
    minutes
    hours
    days
}
languages {
    enabled
    values
}
`

export const productFragment = `
    delivery{
        ownDelivery{
            note
            enabled
            price
            freeAfterOrderPrice
            pricing
            preparationDelay
        }

        takeAway{
            note
            preparationDelay
            enabled
        }

        shippingOptions{
            enabled
            service
            preparationDelay
            weight
            extraCostEnabled
            extraCost
            extraCostPerAdditionalProduct
            price
            costPerAdditionalProduct
            freeAfterOrderPrice
        }
    }
`

const marketItemFragment = `
id
title
description
note
enabled
tva
type
category {
    id
}
options {
    id
    label
    type
    values {
        id
        label
    }
    choices {
        id
        label
    }
    tva
    price
}
quotas {
    enabled
    type
    quantity
    items {
        language
        pricing
        options
        quantity
    }
}
pricing {
    unique
    basePrice
    note
    list {
        id
        label
        price
        description
    }
    tva {
        unique
        percentage
        list {
            amount
            percentage
        }
    }
}
miniature {
    public_id
	url
}
time_limit
delivery {
    ownDelivery {
        note
        enabled
        freeAfterOrderPrice
        preparationDelay
        price
        pricing
    }
    takeAway {
        note
        preparationDelay
        enabled
    }
    shippingOptions {
        enabled
        service
        preparationDelay
        price
        costPerAdditionalProduct
        freeAfterOrderPrice
    }
}
giftPackage {
    available
    price
}
creation {
    complete
    currentStep
}
informations {
    moods {
        id
        name
    }
    gender
    who
    labels
}

characteristics {
    weight
    length
    height
    width
    volume
}
gallery{
    public_id
	url
}
reservation_policy{
    description
    confirmation_method
    cancellation
    weather_cancellation
    reservation_email_ids
    refundPolicy{
      enabled
      delay
    }
    exchangePolicy{
      enabled
      delay
    }
    cancellationPolicy{
      enabled
      delay
    }
    reservationDeadline {
        enabled
        daysDelay
    }
}
optionsQuotas {
	pricing
	options
	quota
}
activities {
	id
	slug
	title
	description
	contacts {
		mail
		phone
	}
	address {
		full_address
		postal_code
		locality
		location {
			lng
			lat
		}
	}
	destinations {
		data
	}
	miniature {
		public_id
		url
	}
	schedules {
		same_schedule
		description
		closed_periods {
			start_date
			end_date
		}
		schedules_table {
			start_date
			end_date
			global
			days {
				name
				start {
					hours
					minutes
				}
				end {
					hours
					minutes
				}
				start_pause {
					hours
					minutes
				}
				end_pause {
					hours
					minutes
				}
				options {
					closed
					has_pause
				}
			}
		}
	}
}

${serviceFragment}
${productFragment}
`

const searchResultMarketItemFragment = `
id
title
enabled
type
category {
    id
}
pricing {
    unique
    basePrice
    list {
        id
        price
    }
}
miniature {
    public_id
	url
}
informations {
    moods {
        id
        name
    }
    gender
    who
    labels
}
gallery{
    public_id
	url
}

activities {
	miniature {
		public_id
		url
	}
}
`

const lightMarketItemFragment = `
id
title
enabled
type
category {
    id
}
pricing {
    unique
    basePrice
    list {
        id
        price
    }
}
miniature {
    public_id
	url
}
informations {
    moods {
        id
        name
    }
    gender
    who
    labels
}
gallery{
    public_id
	url
}
`

const activitiesMiniatureFragment = `activities {
    miniature {
        public_id
		url
    }
}`
